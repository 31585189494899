import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Container,
  Grid,
  Typography,
  Button,
  Snackbar,
  Alert,
  Tooltip,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ACCOUNT_ID,
  CREATED_BY_ID,
  CREATED_BY_NAME,
  Pages,
  PENDING,
  PENDING_STATUS,
} from "../../constants/enums";
import useFormValidations from "../../hooks/useFormValidations";
import ButtonLoader from "../UI/ButtonLoader";
import FormField from "../UI/FormFields/FormFields";
import useFormFields from "../../hooks/useFormFields";
import { Booking } from "../../models/booking";
import Separator from "../Separator/Separator";
import Footer from "../Footer/Footer";
import { setBooking, setView } from "../../store/ducks/app";
import { isDateInPast } from "../../helpers/utils";
import useApp from "../../hooks/useApp";
import MainLoader from "../MainLoader";
import * as yup from "yup";
import dayjs, { Dayjs } from "dayjs";

interface Props {
  name: string;
}

const FieldName: React.FC<Props> = ({ name }) => {
  return (
    <Typography variant="body1" align="left" style={{ color: "#DACAA7" }}>
      {name}
    </Typography>
  );
};

interface FormProps {
  venue: any;
  loading: boolean;
}

const BookingForm: React.FC<FormProps> = ({ venue, loading }) => {
  // console.log({ venue });

  const { showError } = useApp();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [successBar, setSuccessBar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [bookingType, setBookingType] = useState<string>("Event Booking");

  // const { bookingFields, userInfoFields } = useFormFields({
  //   slug: Pages.BOOKING,
  // });

  const { bookingFields, userInfoFields } = useFormFields(
    { slug: Pages.BOOKING },
    bookingType,
    selectedDate
  );
  const { getValidationSchema } = useFormValidations(Pages.BOOKING);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<any>({ resolver: yupResolver(getValidationSchema()) });

  // Watch the `date` field to trigger conditional UI updates
  const watchedDate = watch("date");

  // useEffect(() => {
  //   if (bookingType === "Event Booking") {
  //     const eventDate = dayjs("2025-01-26").format("YYYY-MM-DD");
  //     setSelectedDate(eventDate);
  //     setValue("date", eventDate);
  //     setValue("pax", 2);
  //   }

  //   if (watchedDate) {
  //     setSelectedDate(watchedDate);

  //     // Dynamically set default value and constraints if specific date is chosen
  //     if (watchedDate === "2025-01-26") {
  //       setValue("pax", 2); // Default to 2 for this date
  //     }
  //   }
  // }, [watchedDate, setValue]);

  // useEffect(() => {
  //   if (bookingType === "Event Booking") {
  //     const startDate = dayjs("2025-01-26");
  //     const { nextFriday, nextSaturday, nextSunday } =
  //       getNextFridaySaturdayAndSunday(startDate);

  //     // Determine the next valid event date (Friday, Saturday, or Sunday)
  //     const eventDate = dayjs(nextFriday).isBefore(dayjs("2025-02-28"))
  //       ? nextFriday
  //       : dayjs(nextSaturday).isBefore(dayjs("2025-02-28"))
  //       ? nextSaturday
  //       : nextSunday;

  //     // Set the selected date and form value
  //     setSelectedDate(eventDate);
  //     setValue("pax", 2); // Default pax for event booking
  //     // setValue("date", eventDate);
  //   }

  //   if (watchedDate) {
  //     setSelectedDate(watchedDate);

  //     // Check if the watchedDate is one of the next Friday, Saturday, or Sunday
  //     const startDate = dayjs("2025-01-26");
  //     const { nextFriday, nextSaturday, nextSunday } =
  //       getNextFridaySaturdayAndSunday(startDate);

  //     if (
  //       watchedDate === nextFriday ||
  //       watchedDate === nextSaturday ||
  //       watchedDate === nextSunday
  //     ) {
  //       setValue("pax", 2); // Set pax to 2 if the date is a valid event date
  //     }
  //   }
  // }, [watchedDate, setValue]);

  useEffect(() => {
    if (bookingType === "Event Booking") {
      const startDate = dayjs("2025-01-26");
      const endDate = dayjs("2025-02-28");

      // Get all Fridays, Saturdays, and Sundays within the date range
      const eventDates = getAllFridaysSaturdaysAndSundays(startDate, endDate);

      // Check if the selectedDate is a valid event date
      if (!eventDates.includes(selectedDate)) {
        // If not, set the selectedDate to the next valid event date
        const nextEventDate = eventDates.find((date) =>
          dayjs(date).isAfter(dayjs())
        );
        if (nextEventDate) {
          setSelectedDate(nextEventDate);
          setValue("date", nextEventDate);
        }
      }

      // Set default pax for event booking
      setValue("pax", 2);
    }

    if (watchedDate) {
      setSelectedDate(watchedDate);

      // Check if the watchedDate is one of the valid event dates
      const startDate = dayjs("2025-01-26");
      const endDate = dayjs("2025-02-28");
      const eventDates = getAllFridaysSaturdaysAndSundays(startDate, endDate);

      if (eventDates.includes(watchedDate)) {
        setValue("pax", 2); // Set pax to 2 if the date is a valid event date
      }
    }
  }, [watchedDate, setValue, bookingType, selectedDate]);

  // const getNextFridaySaturdayAndSunday = (
  //   startDate: Dayjs
  // ): { nextFriday: string; nextSaturday: string; nextSunday: string } => {
  //   let nextFriday = startDate;
  //   let nextSaturday = startDate;
  //   let nextSunday = startDate;

  //   // Find the next Friday
  //   while (nextFriday.day() !== 5) {
  //     nextFriday = nextFriday.add(1, "day");
  //   }

  //   // Find the next Saturday
  //   while (nextSaturday.day() !== 6) {
  //     nextSaturday = nextSaturday.add(1, "day");
  //   }

  //   // Find the next Sunday
  //   while (nextSunday.day() !== 0) {
  //     nextSunday = nextSunday.add(1, "day");
  //   }

  //   return {
  //     nextFriday: nextFriday.format("YYYY-MM-DD"),
  //     nextSaturday: nextSaturday.format("YYYY-MM-DD"),
  //     nextSunday: nextSunday.format("YYYY-MM-DD"),
  //   };
  // };

  const getAllFridaysSaturdaysAndSundays = (
    startDate: Dayjs,
    endDate: Dayjs
  ): string[] => {
    const eventDates: string[] = [];
    let currentDate = startDate;

    // Loop through all dates from startDate to endDate
    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const day = currentDate.day();

      // Check if the current date is a Friday (5), Saturday (6), or Sunday (0)
      if (day === 5 || day === 6 || day === 0) {
        eventDates.push(currentDate.format("YYYY-MM-DD"));
      }

      // Move to the next day
      currentDate = currentDate.add(1, "day");
    }

    return eventDates;
  };

  // if one day
  // const handleBookingTypeChange = (event: SelectChangeEvent<string>) => {
  //   const selectedType = event.target.value;
  //   setBookingType(selectedType);

  //   // Update selectedDate based on booking type
  //   if (selectedType === "Normal Booking") {
  //     const today = dayjs().format("YYYY-MM-DD");
  //     setSelectedDate(today);
  //     setValue("date", today); // Update the form field value directly
  //   } else if (selectedType === "Event Booking") {
  //     const eventDate = dayjs("2025-01-26").format("YYYY-MM-DD");
  //     setSelectedDate(eventDate);
  //     setValue("date", eventDate); // Update the form field value directly
  //     setValue("pax", 2); // Optionally set pax value
  //   }
  // };

  // const handleBookingTypeChange = (event: SelectChangeEvent<string>) => {
  //   const selectedType = event.target.value;
  //   setBookingType(selectedType);

  //   // Update selectedDate based on booking type
  //   if (selectedType === "Normal Booking") {
  //     const today = dayjs().format("YYYY-MM-DD");
  //     setSelectedDate(today);
  //     setValue("date", today); // Update the form field value directly
  //   } else if (selectedType === "Event Booking") {
  //     const startDate = dayjs("2025-01-26");
  //     const { nextFriday, nextSaturday, nextSunday } =
  //       getNextFridaySaturdayAndSunday(startDate);

  //     // Check if the next Friday, Saturday, or Sunday is within the range till 2025-02-28
  //     const endDate = dayjs("2025-02-28");

  //     // Determine the next valid event date (Friday, Saturday, or Sunday)
  //     let eventDate: string;
  //     if (dayjs(nextFriday).isBefore(endDate)) {
  //       eventDate = nextFriday; // Use next Friday if it's within the range
  //     } else if (dayjs(nextSaturday).isBefore(endDate)) {
  //       eventDate = nextSaturday; // Use next Saturday if it's within the range
  //     } else {
  //       eventDate = nextSunday; // Use next Sunday if Friday and Saturday are out of range
  //     }

  //     // Update the form field values
  //     setValue("date", eventDate); // Update the form field value directly
  //     setValue("pax", 2); // Optionally set pax value
  //   }
  // };
  const handleBookingTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedType = event.target.value;
    setBookingType(selectedType);

    // Update selectedDate based on booking type
    if (selectedType === "Normal Booking") {
      const today = dayjs().format("YYYY-MM-DD");
      setSelectedDate(today);
      setValue("date", today); // Update the form field value directly
    } else if (selectedType === "Event Booking") {
      const startDate = dayjs("2025-01-26");
      const endDate = dayjs("2025-02-28");

      // Get all Fridays, Saturdays, and Sundays within the date range
      const eventDates = getAllFridaysSaturdaysAndSundays(startDate, endDate);

      // Find the next valid event date (Friday, Saturday, or Sunday)
      const nextEventDate = eventDates.find((date) =>
        dayjs(date).isAfter(dayjs())
      );

      // Set the eventDate to the next valid event date
      const eventDate = nextEventDate || eventDates[0]; // Fallback to the first event date if no future dates are found

      // Update the form field values
      setValue("date", eventDate); // Update the form field value directly
      setValue("pax", 2); // Optionally set pax value
    }
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    try {
      // Date validation
      if (data && data.date) {
        const result = isDateInPast(data.date);
        if (result) {
          showError("Not possible to reserve on a previous date.");
          return;
        }
      }

      // Prepare Booking Object
      data.mobile = data.mobile.replace(/\s/g, "");
      const phoneNumber =
        data.mobile.slice(0, 1) !== "0" ? `0${data.mobile}` : data.mobile;

      let comments: string[] = [];
      if (data.area) {
        comments.push(`Area: ${data.area}`);
      }
      if (data.area) {
        comments.push(`Meal: ${data.meal}`);
      }
      if (data.note) {
        comments.push(data.note);
      }
      if (data.coupon) {
        comments.push(`Promocode: ${data.coupon}`);
      }

      const bookingData: Booking = {
        conceptID: venue ? venue.id : "",

        accompaniedCount: data ? data.pax : 1,
        date: data ? data.date : new Date(),
        timeSlotID: data ? data.time : "",
        timeSlots: data ? [data.time] : [],

        mainGuest: "",
        customerGroup: "normal",
        customerName: data ? data.name : "",
        customerPhone: phoneNumber,
        email: data ? data.email.replace(/\s/g, "") : "",

        comments: comments,
        lastComment: comments.length > 0 ? comments[comments.length - 1] : "",

        tables: [],
        eventGuests: [],
        depositValue: "0",
        channel: "mobile app",
        statusID: bookingType !== "Event Booking" ? PENDING : PENDING_STATUS,

        deleted: "0",
        createdAt: new Date().toLocaleString(),

        // Static Values
        createdByID: CREATED_BY_ID,
        createdByName: CREATED_BY_NAME,
        accountID: ACCOUNT_ID,
      };

      dispatch(setBooking(bookingData));
      dispatch(setView("bookingOTP"));
    } catch (error) {
      console.error("Error parsing booking data:", error);
      // Show Error Message
      setOpen(true);
      showError(error);
    }
  };

  // Define the date range
  const startDate = dayjs("2025-01-26");
  const endDate = dayjs("2025-02-28");

  // Get all Fridays, Saturdays, and Sundays within the date range
  const eventDates = getAllFridaysSaturdaysAndSundays(startDate, endDate);

  // Check if the selectedDate is one of the event dates
  const isEventDate = eventDates.includes(selectedDate);
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          width: "90%",
          marginTop: "40px",
        }}
      >
        <Typography variant="h4" align="center" style={styles.title}>
          Reserve a Table
        </Typography>

        {/* Conditional UI for specific date */}
        {isEventDate && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "#DACAA7", my: 2 }}>
              Event: Sushi Cooking Class
            </Typography>
          </Box>
        )}

        {!loading && venue ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={2}>
              <Grid container columnSpacing={2} style={styles.formRow}>
                <Grid item xs={2} lg={2} md={2}>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{ color: "#DACAA7" }}
                  >
                    Type
                  </Typography>
                </Grid>

                <Grid item xs={10} lg={10} md={10}>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Border color white
                        },
                        "&:hover fieldset": {
                          borderColor: "#DACAA7", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#DACAA7", // Focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "white", // Label color white
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#DACAA7", // Focused label color
                      },
                      input: { color: "white" }, // Text color white
                    }}
                  >
                    <InputLabel id="booking-type-label">
                      Booking Type
                    </InputLabel>
                    <Select
                      labelId="booking-type-label"
                      value={bookingType}
                      onChange={handleBookingTypeChange}
                      label="Booking Type"
                      sx={{
                        color: "white",
                      }}
                    >
                      <MenuItem value="Normal Booking">Normal Booking</MenuItem>
                      <MenuItem value="Event Booking">
                        Event Booking - Sushi Cooking Class
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {bookingFields(venue).map((field: any) => (
                <Grid
                  container
                  columnSpacing={2}
                  style={styles.formRow}
                  key={field.name}
                >
                  <Grid item xs={2} lg={2} md={2}>
                    <FieldName name={field.label}></FieldName>
                  </Grid>
                  <Grid item xs={10} lg={10} md={10}>
                    {field.name === "pax" ? (
                      <Controller
                        name="pax"
                        control={control}
                        render={({ field }) => (
                          <>
                            {isEventDate ? (
                              <Tooltip
                                title="Pax must be between 2 and 4 for this date."
                                placement="top"
                              >
                                <TextField
                                  {...field}
                                  type="number"
                                  placeholder="Enter number of guests"
                                  InputProps={{
                                    inputProps: {
                                      min: 2,
                                      max: 4,
                                    },
                                  }}
                                  value={field.value}
                                  onChange={(e) => {
                                    const value = Number(e.target.value);
                                    // if (value >= 2 && value <= 4) {
                                    //   field.onChange(value);
                                    // }
                                    // Allow clearing the input
                                    if (value === 0) {
                                      field.onChange("");
                                      return;
                                    }

                                    // Validate number range
                                    const numericValue = Number(value);
                                    if (
                                      numericValue >= 2 &&
                                      numericValue <= 4
                                    ) {
                                      field.onChange(numericValue);
                                    }
                                  }}
                                  sx={{
                                    input: { color: "white" }, // Text color white
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "white", // Border color white
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#DACAA7", // Hover border color
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#DACAA7", // Focus border color
                                      },
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "white", // Label color white
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                      color: "#DACAA7", // Focused label color
                                    },
                                  }}
                                  fullWidth
                                />
                              </Tooltip>
                            ) : (
                              <TextField
                                {...field}
                                type="number"
                                placeholder="Enter number of guests"
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                    max: 99,
                                  },
                                }}
                                value={field.value}
                                onChange={(e) => {
                                  // field.onChange(Number(e.target.value));
                                  const value = Number(e.target.value);

                                  if (value === 0) {
                                    field.onChange("");
                                    return;
                                  }

                                  // Validate number range
                                  const numericValue = Number(value);
                                  if (numericValue >= 1 && numericValue <= 99) {
                                    field.onChange(numericValue);
                                  }
                                }}
                                sx={{
                                  input: { color: "white" }, // Text color white
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "white", // Border color white
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#DACAA7", // Hover border color
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#DACAA7", // Focus border color
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "white", // Label color white
                                  },
                                  "& .MuiInputLabel-root.Mui-focused": {
                                    color: "#DACAA7", // Focused label color
                                  },
                                }}
                                fullWidth
                              />
                            )}
                          </>
                        )}
                      />
                    ) : field.name === "date" ? (
                      // Time field custom logic
                      <FormField
                        key={field.name}
                        control={control}
                        name={field.name}
                        label={field.label}
                        placeholder={field.placeholder}
                        type={field.type}
                        autoFocus={field.autoFocus}
                        defaultValue={field.defaultValue}
                        options={field.options}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        disabled={field.disabled}
                        hidden={field.hidden}
                        isEvent={bookingType === "Event Booking"}
                      />
                    ) : (
                      // ) : field.name === "time" ? (
                      //   // Time field custom logic
                      //   <FormField
                      //     key={field.name}
                      //     control={control}
                      //     name={field.name}
                      //     label={field.label}
                      //     placeholder={field.placeholder}
                      //     type={field.type}
                      //     autoFocus={field.autoFocus}
                      //     defaultValue={field.defaultValue}
                      //     options={
                      //       selectedDate === "2024-12-06"
                      //         ? field.options.filter((option: any) =>
                      //             [
                      //               "84d1cecf-027f-4264-8960-e06cc4884fca",
                      //               "253f3b7e-7a75-4c28-b7d0-1332cdaddfb7",
                      //               "1f63f38d-1bdf-4845-835c-92053ce61ecf",
                      //             ].includes(option.value)
                      //           )
                      //         : field.options
                      //     }
                      //     register={register}
                      //     setValue={setValue}
                      //     errors={errors}
                      //     disabled={field.disabled}
                      //     hidden={field.hidden}
                      //   />
                      <FormField
                        key={field.name}
                        control={control}
                        name={field.name}
                        label={field.label}
                        placeholder={field.placeholder}
                        type={field.type}
                        autoFocus={field.autoFocus}
                        defaultValue={field.defaultValue}
                        options={field.options}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        disabled={field.disabled}
                        hidden={field.hidden}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}

              {/* Separator line CSS */}
              <Separator></Separator>

              {userInfoFields().map((field: any) => (
                <Grid
                  container
                  columnSpacing={2}
                  style={styles.formRow}
                  key={field.name}
                >
                  <Grid item xs={2} lg={2} md={2}>
                    <FieldName name={field.label}></FieldName>
                  </Grid>
                  <Grid item xs={10} lg={10} md={10}>
                    <FormField
                      key={field.name}
                      control={control}
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      defaultValue={field.defaultValue}
                      options={field.options}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      disabled={field.disabled}
                      hidden={field.hidden}
                    />
                  </Grid>
                </Grid>
              ))}

              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disableElevation
                  endIcon={isSubmitting && <ButtonLoader />}
                  disabled={isSubmitting}
                  style={styles.submitButton}
                >
                  Book Now
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <MainLoader></MainLoader>
        )}

        {/* Footer */}
        <Footer></Footer>

        {/* Success Message */}
        <Snackbar
          open={successBar}
          autoHideDuration={3000}
          onClose={() => {
            setSuccessBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSuccessBar(false);
            }}
            severity="success"
          >
            Your reservation was successfully created.
          </Alert>
        </Snackbar>

        {/* Error Message */}
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpen(false);
            }}
            severity="error"
          >
            Unfortunately, we were unable to complete your reservation. Please
            try again!
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

const styles = {
  title: {
    marginTop: "1vh",
    marginBottom: "3vh",
    color: "#DACAA7",
    fontSize: "30px",
    fontFamily: "Cormorant Garamond",
    fontStyle: "normal",
    lineHeight: "normal",
  },
  formRow: {
    marginTop: "1vh",
  },
  formInput: {
    border: "1px solid white",
    borderRadius: "5px",
    paddingLeft: "15px",
    height: "6vh",
    color: "white",
  },
  submitButton: {
    minHeight: "50px",
    minWidth: "220px",
    marginTop: "1vh",
    marginBottom: "3vh",
    borderRadius: "0px",
    border: "0.3px solid #dacaa7",
    background:
      "linear-gradient(91deg, #5E273A -4.95%, rgba(170, 52, 13, 0.00) 105.66%)",
  },
};

export default BookingForm;
